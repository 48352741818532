import ReactGA from 'react-ga'
import React, { Component } from 'react';

const isDevelopment = process.env.GATSBY_ISDEVELOPMENT == 'true'

if (!isDevelopment) {
    ReactGA.initialize('UA-133550840-2')
}

export const sendEvent = (category: string, action: string) => !isDevelopment && ReactGA.send({
    hitType: 'event',
    category,
    action
})

export default class GoogleAnalytics extends Component<{ pathname: string, children: JSX.Element | JSX.Element[] }> {
    render() {
        if (!isDevelopment) {
            ReactGA.pageview(this.props.pathname)
        }
        return this.props.children;
    }
}